import React from 'react';
import PropTypes from 'prop-types';
import TetherComponent from 'react-tether';
import uniq from 'lodash/uniq';

import Icon from '../Icon';

import useOutClick from '../../hooks/use-out-click';

import './Select.css';

export default function Select({
  mini,
  multi,
  clearable,
  disabled,
  open,
  options,
  value,
  label,
  placeholder,
  persistPlaceholder,

  onChange,
  onFocus,
  onBlur,
  onSearch,

  tetherProps,
  getTetherProps,
  wrapperClassName,
  wrapperProps,
  dropdownWrapperClassName,
  dropdownWrapperProps,
  dropdownClassName,
  dropdownProps,
  searchClassName,
  searchProps,
  searchInputClassName,
  searchInputProps,
  searchIconProps,
  optionsClassName,
  optionsProps,
  optionClassName,
  optionProps,

  renderBefore,
  renderAfter,
  renderLabel,
  renderValue,
  renderDropdownBefore,
  renderDropdownAfter,
  renderOptionsBefore,
  renderOptionsAfter,
  renderOptionBefore,
  renderOptionAfter,
  renderOption,
  renderSearch,

  defaultPlaceholder,
  defaultSearch,
}) {
  const [isOpen, setIsOpen] = React.useState(open);
  const [dropdownWidth, setDropdownWidth] = React.useState(200);
  const [search, setSearch] = React.useState(defaultSearch);
  const searchInputRef = React.useRef(null);
  const selectRef = React.useRef();
  const dropdownRef = React.useRef();

  useOutClick([selectRef, dropdownRef], () => {
    setIsOpen(false);
  }, isOpen, [isOpen]);

  React.useEffect(() => {
    setIsOpen(open);
    if (selectRef && selectRef.current) {
      setDropdownWidth(selectRef.current.getBoundingClientRect().width);
    }
  }, [open]);

  React.useEffect(() => {
    if (selectRef && selectRef.current) {
      setDropdownWidth(selectRef.current.getBoundingClientRect().width);
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (isOpen && searchInputRef && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isOpen]);

  const handleFocus = () => {
    if (disabled) return false;
    setIsOpen(true);
    if (onFocus) onFocus(value);
  };

  const handleBlur = () => {
    if (disabled) return false;
    setIsOpen(false);
    setSearch('');
    if (onBlur) onBlur(value);
  };

  const handleOpen = () => {
    if (disabled) return false;
    if (isOpen) {
      return handleBlur();
    }
    handleFocus();
  };

  const handleSearch = ({ target }) => {
    const v = target.value;
    setSearch(v);
    if (onSearch) onSearch(v);
  };

  const handleSelect = (option) => {
    if (disabled) return false;
    if (multi) {
      if ((value || []).indexOf(option.value) === -1) {
        onChange(uniq([
          ...(value || []),
          option.value,
        ]));
      } else {
        onChange(uniq(value.filter((v) => v !== option.value)));
      }
    } else {
      onChange([option.value]);
      handleBlur();
    }
  };

  const handleSearchSelect = ({ key }) => {
    if (key === 'Enter' && search !== '') {
      const filtered = (options || []).filter((option) => `${option.value}-${option.label}`.toLowerCase().indexOf(search.toLowerCase()) !== -1);
      if (filtered[0]) {
        handleSelect(filtered[0]);
      }
    }
  };

  const handleClear = (e) => {
    if (disabled) return false;
    e.stopPropagation();
    onChange([]);
    handleBlur();
  };

  const displayValue = (() => {
    const ph = placeholder || defaultPlaceholder;
    const v = value || [];

    if (persistPlaceholder || v.length === 0) {
      return ph;
    }

    if (v.length > 1) {
      return `${value.length} Selected`;
    }

    return (options.find((o) => o.value === value[0]) || {}).label || ph;
  })();

  return (
    <TetherComponent
      {...getTetherProps(tetherProps)}
      renderTarget={(ref) => (
        <div
          ref={ref}
          className={`select-wrapper ${isOpen ? 'select-focused' : ''} ${mini ? 'select-mini' : ''} ${disabled ? 'select-disabled' : ''} ${wrapperClassName || ''}`}
          {...wrapperProps}
        >
          <div ref={selectRef} onClick={handleOpen} className="select-wrapper-child">
            {renderBefore && renderBefore({ open, value })}
            <div>
              {renderLabel
                ? renderLabel({
                  label,
                  open,
                  handleFocus,
                  handleBlur,
                }) : label && (
                  <label className="input-label">{label}</label>
                )}
              {renderValue
                ? renderValue({
                  open,
                  value,
                  clearable,
                  displayValue,
                  placeholder,
                  defaultPlaceholder,
                  handleOpen,
                  handleFocus,
                  handleBlur,
                  handleClear,
                })
                : (
                  <div className="select-value">
                    <span>{displayValue}</span>
                    <div className="select-value-icons">
                      {clearable && <Icon className="select-value-clear" title="Clear" i="x-circle" size={14} onClick={handleClear} />}
                      <Icon className="select-value-arrow" i="chevron-down" size={14} />
                    </div>
                  </div>
                )}
            </div>
            {renderAfter && renderAfter({ open, value })}
          </div>
        </div>
      )}
      renderElement={(ref) => isOpen && (
        <div ref={ref} className={`select-dropdown-wrapper ${isOpen ? 'select-focused' : ''} ${mini ? 'select-mini' : ''} ${disabled ? 'select-disabled' : ''} ${dropdownWrapperClassName || ''}`} style={{ minWidth: dropdownWidth }} {...dropdownWrapperProps}>
          <div ref={dropdownRef} className={`select-dropdown ${dropdownClassName || ''}`} {...dropdownProps}>
            {renderDropdownBefore && renderDropdownBefore({ open, options, value })}
            {renderSearch
              ? renderSearch({ open, options, value })
              : (
                <div className={`select-search ${searchClassName || ''}`} {...searchProps}>
                  <Icon i="search" size={14} {...searchIconProps} />
                  <input
                    ref={searchInputRef}
                    className={`select-search-input ${searchInputClassName || ''}`}
                    type="text"
                    placeholder="Search..."
                    spellCheck={false}
                    value={search}
                    onChange={handleSearch}
                    onKeyPress={handleSearchSelect}
                    {...searchInputProps}
                  />
                </div>
              )}
            <div className={`select-options ${optionsClassName || ''}`} {...optionsProps}>
              {renderOptionsBefore && renderOptionsBefore({ open, options, value })}
              {(options || []).filter((option) => {
                if (!search) return true;
                return `${option.value}-${option.label}`.toLowerCase().indexOf(search.toLowerCase()) !== -1;
              }).map((option, index) => {
                const isSelected = (value || []).some((v) => v === option.value);

                return (
                  <div
                    key={`select-option-${index}-${option.value}`}
                    className={`select-option ${isSelected ? 'select-option-selected' : ''} ${option.className || ''} ${optionClassName || ''}`}
                    onClick={() => handleSelect(option)}
                    {...optionProps}
                    {...option.props}
                  >
                    {renderOptionBefore && renderOptionBefore({
                      open,
                      options,
                      value,
                      option,
                      isSelected,
                    })}
                    {renderOption ? renderOption({
                      open,
                      options,
                      value,
                      option,
                      isSelected,
                    }) : option.label}
                    {renderOptionAfter && renderOptionAfter({
                      open,
                      options,
                      value,
                      option,
                      isSelected,
                    })}
                  </div>
                );
              })}
              {renderOptionsAfter && renderOptionsAfter({ open, options, value })}
            </div>
            {renderDropdownAfter && renderDropdownAfter({ open, options, value })}
          </div>
        </div>
      )}
    />
  );
}

Select.propTypes = {
  mini: PropTypes.bool,
  multi: PropTypes.bool,
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
  open: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape()),

  label: PropTypes.string,
  placeholder: PropTypes.string,
  persistPlaceholder: PropTypes.bool,

  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onSearch: PropTypes.func,

  wrapperClassName: PropTypes.string,
  dropdownWrapperClassName: PropTypes.string,
  dropdownClassName: PropTypes.string,
  searchClassName: PropTypes.string,
  searchInputClassName: PropTypes.string,
  optionsClassName: PropTypes.string,
  optionClassName: PropTypes.string,
  defaultPlaceholder: PropTypes.string,
  defaultSearch: PropTypes.string,

  tetherProps: PropTypes.shape(),
  getTetherProps: PropTypes.shape(),
  wrapperProps: PropTypes.shape(),
  dropdownWrapperProps: PropTypes.shape(),
  dropdownProps: PropTypes.shape(),
  searchProps: PropTypes.shape(),
  searchInputProps: PropTypes.shape(),
  searchIconProps: PropTypes.shape(),
  optionsProps: PropTypes.shape(),
  optionProps: PropTypes.shape(),
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape(),
      PropTypes.string,
      PropTypes.number,
    ]),
  ),

  renderBefore: PropTypes.func,
  renderAfter: PropTypes.func,
  renderLabel: PropTypes.func,
  renderValue: PropTypes.func,
  renderDropdownBefore: PropTypes.func,
  renderDropdownAfter: PropTypes.func,
  renderOptionsBefore: PropTypes.func,
  renderOptionsAfter: PropTypes.func,
  renderOptionBefore: PropTypes.func,
  renderOptionAfter: PropTypes.func,
  renderOption: PropTypes.func,
  renderSearch: PropTypes.func,
};

Select.defaultProps = {
  mini: false,
  multi: false,
  clearable: false,
  disabled: false,
  open: false,
  options: [],
  value: null,
  label: null,
  placeholder: null,
  persistPlaceholder: false,

  tetherProps: {
    attachment: 'top left',
    targetAttachment: 'bottom left',
    constraints: [
      {
        to: 'window',
        attachment: 'together',
        pin: true,
      },
    ],
  },

  getTetherProps: (props) => props,
  onFocus: null,
  onBlur: null,
  onSearch: null,
  renderBefore: null,
  renderAfter: null,
  renderLabel: null,
  renderValue: null,
  renderDropdownBefore: null,
  renderDropdownAfter: null,
  renderOptionsBefore: null,
  renderOptionsAfter: null,
  renderOptionBefore: null,
  renderOptionAfter: null,
  renderOption: null,
  renderSearch: null,

  wrapperProps: {},
  dropdownWrapperProps: {},
  dropdownProps: {},
  searchProps: {},
  searchInputProps: {},
  searchIconProps: {},
  optionsProps: {},
  optionProps: {},

  dropdownWrapperClassName: '',
  searchInputClassName: '',
  dropdownClassName: '',
  wrapperClassName: '',
  optionsClassName: '',
  searchClassName: '',
  optionClassName: '',
  defaultSearch: '',
  defaultPlaceholder: 'Select',
};
