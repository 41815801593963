import React from 'react';
import TetherComponent from 'react-tether';
import PropTypes from 'prop-types';

import './Tooltip.css';

const pos = {
  left: {
    attachment: 'middle right',
    targetAttachment: 'middle left',
  },
  right: {
    attachment: 'middle left',
    targetAttachment: 'middle right',
  },
  bottom: {
    attachment: 'top center',
    targetAttachment: 'bottom center',
  },
  top: {
    attachment: 'bottom center',
    targetAttachment: 'top center',
  },
};

function Tooltip({
  isOpen,
  stayOpen,
  stayClose,
  openOnHover,
  position,

  TargetElement,
  targetElementProps,
  tetherProps,
  getTetherProps,

  tooltip,
  tooltipClassName,
  tooltipProps,

  children,
}) {
  const [open, setOpen] = React.useState(isOpen);

  React.useEffect(() => {
    if (!openOnHover) {
      setOpen(isOpen);
    }
  }, [isOpen, openOnHover]);

  return (
    <TetherComponent
      {...getTetherProps(tetherProps)}
      attachment={pos[position].attachment}
      targetAttachment={pos[position].targetAttachment}
      renderTarget={(ref) => (
        <TargetElement
          {...targetElementProps}
          ref={ref}
          className="tooltip-target"
          onMouseOver={() => openOnHover && setOpen(true)}
          onMouseLeave={() => openOnHover && setOpen(false)}
        >
          {children}
        </TargetElement>
      )}
      renderElement={(ref) => !stayClose && (stayOpen || open) && (
        <div
          ref={ref}
          className={`tooltip is-${position} ${tooltipClassName}`}
          {...tooltipProps}
        >
          {tooltip}
        </div>
      )}
    />
  );
}

Tooltip.propTypes = {
  isOpen: PropTypes.bool,
  stayOpen: PropTypes.bool,
  stayClose: PropTypes.bool,
  openOnHover: PropTypes.bool,
  position: PropTypes.oneOf(Object.keys(pos)),

  TargetElement: PropTypes.string,
  targetElementProps: PropTypes.shape(),
  tetherProps: PropTypes.shape(),
  getTetherProps: PropTypes.func,

  tooltip: PropTypes.node.isRequired,
  tooltipClassName: PropTypes.string,
  tooltipProps: PropTypes.shape(),

  children: PropTypes.node.isRequired,
};

Tooltip.defaultProps = {
  isOpen: false,
  stayOpen: false,
  stayClose: false,
  openOnHover: true,
  position: 'bottom',

  TargetElement: 'div',
  targetElementProps: {},
  tetherProps: {
    classes: {
      target: 'tooltip-target',
      element: 'tooltip-portal',
    },
  },
  getTetherProps: (props) => props,

  tooltipClassName: '',
  tooltipProps: {},
};

export default Tooltip;
