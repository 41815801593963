import React from 'react';
import PropTypes from 'prop-types';

import Radio from '../Radio';

import './RadioGroup.css';

const RadioGroup = ({
  disabled,
  name,
  value,
  options,
  label,
  wrapperProps,
  optionsProps,

  onChange,

  renderBefore,
  renderAfter,
  renderLabel,
}) => {
  const [random] = React.useState(Math.random().toString(36).substr(2, 9));

  return (
    <div className={`radio-input-group ${disabled ? 'is-disabled' : ''}`} {...wrapperProps}>
      {renderBefore && renderBefore({ value })}
      {renderLabel ? renderLabel({ value }) : label && <span className="input-label">{label}</span>}
      <div className="radio-input-group-options" {...optionsProps}>
        {options.map(({
          value: optionValue,
          label: radioLabel,
          ...rest
        }, index) => (
          <Radio
            key={`radio-${index}`}
            name={name || `radio-group-${random}`}
            label={radioLabel}
            value={optionValue}
            checked={value === optionValue}
            onChange={onChange}
            {...rest}
          />
        ))}
      </div>
      {renderAfter && renderAfter({ value })}
    </div>
  );
};

RadioGroup.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,

  wrapperProps: PropTypes.shape(),
  optionsProps: PropTypes.shape(),

  onChange: PropTypes.func,
  renderBefore: PropTypes.func,
  renderAfter: PropTypes.func,
  renderLabel: PropTypes.func,
};

RadioGroup.defaultProps = {
  disabled: false,
  name: null,
  label: null,

  wrapperProps: {},
  optionsProps: {},

  onChange: () => null,
  renderBefore: null,
  renderAfter: null,
  renderLabel: null,
};

export default RadioGroup;
