/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';

import ShadowImage from '../ShadowImage';

import { shadowText } from '../../utils/misc';

import UnknownApp from '../../assets/unknown-app.svg';
import AndroidIcon from '../../assets/android-brands.svg';
import AppleIcon from '../../assets/apple-brands.svg';

import './AppBox.css';

const AppBox = ({
  app,
  full,
  showStore,
  isShadow,
}) => {
  const {
    icon_url,
    title,
    category,
    device,
    unknown,
  } = app;
  let icon = null;

  if (device === 'ios') {
    icon = AppleIcon;
  } else if (device === 'android') {
    icon = AndroidIcon;
  }
  return (
    <div className={`app ${full ? 'full' : ''} ${unknown ? 'unknown' : ''}`}>
      <div className="app-icon">
        <ShadowImage src={icon_url || UnknownApp} alt={title} isShadow={isShadow} />
      </div>
      <div className="app-info">
        <span className="app-title">
          {shadowText({ str: title, alt: 'Example App', isShadow })}
        </span>
        {category && <span className="app-category">{category}</span>}
      </div>
      {!unknown && showStore && <div className="app-store-wrapper"><img src={icon} alt={device} /></div>}
    </div>
  );
};

AppBox.propTypes = {
  showStore: PropTypes.bool,
  isShadow: PropTypes.bool,
  full: PropTypes.bool,
  app: PropTypes.shape(),
};

AppBox.defaultProps = {
  app: {
    icon_url: UnknownApp,
    title: 'Unknown App',
    category: '',
    websafe_url: '',
    device: null,
    unknown: true,
  },
  showStore: true,
  isShadow: false,
  full: false,
};

export default AppBox;
