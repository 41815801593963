import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon';
import Button from '../Button';

import './NoData.css';

const NoData = ({
  component: Component,
  buttonProps,
  icon,
  children,
  ...rest
}) => {
  const content = (
    <Component {...rest}>
      <Icon i={icon || 'database'} size={100} />
      <span>{children}</span>
      {buttonProps && <Button {...buttonProps} />}
    </Component>
  );

  return (
    <div className="no-data noresult">
      {content}
    </div>
  );
};

NoData.propTypes = {
  component: PropTypes.elementType,
  buttonProps: PropTypes.shape(),
  icon: PropTypes.string,
  children: PropTypes.node,
};

NoData.defaultProps = {
  component: Fragment,
  buttonProps: null,
  icon: null,
  children: 'Data is not generated yet',
};

export default NoData;
