import React from 'react';
import PropTypes from 'prop-types';

import './FilterBox.css';

const FilterBox = ({ children }) => (
  <div className="filter">
    {children}
  </div>
);

FilterBox.propTypes = {
  children: PropTypes.node.isRequired,
};

const Left = ({ children, ...rest }) => (
  <div className="filter-column filter-column-left" {...rest}>
    {children}
  </div>
);

Left.propTypes = {
  children: PropTypes.node.isRequired,
};

const Right = ({ children, ...rest }) => (
  <div className="filter-column filter-column-right" {...rest}>
    {children}
  </div>
);

Right.propTypes = {
  children: PropTypes.node.isRequired,
};

FilterBox.Left = Left;
FilterBox.Right = Right;

export default FilterBox;
