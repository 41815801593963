import React from 'react';
import PropTypes from 'prop-types';

import './StepsNav.css';

const Step = ({
  active,
  setProgressWidth,
  children,
  ...rest
}) => {
  const itemRef = React.useRef(null);

  React.useEffect(() => {
    if (active && itemRef && itemRef.current) {
      setProgressWidth(itemRef.current.offsetLeft + (itemRef.current.offsetWidth / 2));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, itemRef]);

  return (
    <div ref={itemRef} className={`steps-nav-item ${active ? 'steps-nav-item-active' : ''}`} {...rest}>
      {children}
    </div>
  );
};

Step.propTypes = {
  active: PropTypes.bool,
  setProgressWidth: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

Step.defaultProps = {
  active: false,
};

export const StepsNav = ({ active, children, ...rest }) => {
  const [progressWidth, setProgressWidth] = React.useState(0);

  return (
    <nav className="steps-nav" style={{ '--active': active, '--progressWidth': progressWidth }} {...rest}>
      {children(setProgressWidth)}
    </nav>
  );
};

StepsNav.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.func.isRequired,
};

StepsNav.defaultProps = {
  active: false,
};

StepsNav.Step = Step;

export default StepsNav;
