import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const Image = ({
  src,
  alt,
  defaultImage,
  ...rest
}) => {
  const [source, setSource] = React.useState(src);

  useEffect(() => {
    setSource(src);
  }, [src]);

  const handleError = () => {
    setSource(defaultImage);
  };

  return (
    <img
      src={source}
      onError={handleError}
      alt={alt}
      {...rest}
    />
  );
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  defaultImage: PropTypes.node.isRequired,
};

export default Image;
