import React from 'react';
import feather from 'feather-icons';
import PropTypes from 'prop-types';

import InlineSVG from './InlineSVG';

import './Icon.css';

const Icon = React.forwardRef(({
  i,
  size,
  children,
  className,
  ...rest
}, ref) => {
  const [icon, setIcon] = React.useState();

  React.useEffect(() => {
    if (!feather.icons[i]) {
      import(`../../assets/icons/${i}.svg`)
        .then(LocalIcon => {
          fetch(LocalIcon.default)
            .then(res => res.text())
            .then(setIcon)
            .catch(() => setIcon(null))
        })
        .catch(() => setIcon(null));
    }
  }, [i])

  if (!feather.icons[i]) {
    return icon ? (
      <i ref={ref} className={`icon ${className}`} {...rest}>
        <InlineSVG src={icon} width={size} height={size} />
        {children}
      </i>
    ) : null;
  }

  const svg = feather.icons[i].toSvg({
    width: size,
    height: size,
  });

  return (
    <i ref={ref} className={`icon ${className}`} {...rest}>
      <InlineSVG src={svg} />
      {children}
    </i>
  );
});

Icon.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
  children: PropTypes.element,
  i: PropTypes.string.isRequired,
};

Icon.defaultProps = {
  className: '',
  size: 24,
  children: null,
};

export default Icon;
