
import React from 'react';
import PropTypes from 'prop-types';

export default function Item({
  component: Component,
  className,
  ...rest
}) {
  return (
    <Component
      className={`action-item ${className}`}
      {...rest}
    />
  );
}

Item.propTypes = {
  component: PropTypes.elementType,
  className: PropTypes.string,
};

Item.defaultProps = {
  component: 'div',
  className: '',
};
