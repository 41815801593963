import React from 'react';
import PropTypes from 'prop-types';

import './PageTitle.css';

const Title = ({ children }) => (
  <div className="page-title-title">
    {children}
  </div>
);

Title.propTypes = {
  children: PropTypes.node.isRequired,
};

export const PageTitle = ({ children }) => (
  <div className="page-title">
    {children}
  </div>
);

PageTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

PageTitle.Title = Title;

export default PageTitle;
