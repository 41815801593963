import React from 'react';
import PropTypes from 'prop-types';

import Macbook from './Macbook';
import Icon from '../Icon';

import './AuthSidebar.css';
import './Radikal.css';

export default function AuthSidebar({
  title,
  subtitle,
  content,
  Animation,
}) {
  return (
    <div className="login-slogan">
      <div className="slogan-text">
        <h2>{title}</h2>
        <h3>{subtitle}</h3>
        <div className="slogan-sub-info">{content}</div>
      </div>
      <Animation />
    </div>
  );
}

AuthSidebar.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
  content: PropTypes.node,
  Animation: PropTypes.elementType,
};

AuthSidebar.defaultProps = {
  title: (
    <React.Fragment>
      <b>Intercept</b> mobile ad fraud.<br />
      <b>Preserve</b> your data.<br /> <b>Protect</b> your ROI.
    </React.Fragment>
  ),
  subtitle: 'Get started with a free account',
  content: (
    <React.Fragment>
      <div><Icon i="check" size={13} /> <span>REAL-TIME:</span> Fraudulent clicks will be blocked before converting to an install..</div>
      <div><Icon i="check" size={13} /> <span>SAVE MONEY:</span> Pay only for GENUINE installs and post-install actions.</div>
      <div><Icon i="check" size={13} /> <span>OPTIMISE:</span> Watch us auto optimise your clean traffic.</div>
      <div><Icon i="check" size={13} /> <span>GROW:</span> Watch the sum-total of our optimisation and protection efforts culminate in your businesses’ growth.</div>
    </React.Fragment>
  ),
  Animation: Macbook,
};
