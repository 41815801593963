import React from 'react';
import PropTypes from 'prop-types';
import TetherComponent from 'react-tether';
import DayPicker, { DateUtils } from 'react-day-picker';
import {
  format,
  isSameDay,
  subDays,
  subMonths,
  setDate,
  endOfMonth,
} from 'date-fns';

import Icon from '../Icon';
import Button from '../Button';

import Caption from './Caption';

import 'react-day-picker/lib/style.css';

import './DatePicker.css';

class DatePicker extends React.PureComponent {
  valueRef = React.createRef()

  dropdownRef = React.createRef()

  applyButtonRef = React.createRef()

  endDatePickerRef = React.createRef()

  constructor(props) {
    super(props);

    const {
      open,
      value,
    } = this.props;

    this.state = {
      isOpen: open,
      from: value.from,
      to: value.to,
      enteredTo: value.to,
      rangeSelected: true,
      applied: true,
    };
  }

  getInternalState = (props) => ({
    ...props,
    ...this.state,
  })

  static getDerivedStateFromProps = (props, state) => {
    if (
      !state.isOpen && (
        !isSameDay(state.from, props.value.from)
        || !isSameDay(state.to, props.value.to)
      )
    ) {
      return {
        from: props.value.from,
        to: props.value.to,
        enteredTo: props.value.to,
      };
    }

    return {};
  }

  componentDidMount = () => {
    this.addEventListeners();
  }

  addEventListeners = () => {
    document.addEventListener('mousedown', this.handleOutsideClick);
  }

  componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.handleOutsideClick);
  }

  focusToApply = () => {
    document.activeElement.blur();
    return this.applyButtonRef?.current?.focus();
  }

  focusToEndInput = () => {
    document.activeElement.blur();
    return this.endDatePickerRef?.current?.focus();
  }

  handleOutsideClick = (e) => {
    const { rangeSelected, applied } = this.state;

    if (
      (this.valueRef?.current?.contains(e.target))
      || (this.dropdownRef?.current?.contains(e.target))
    ) return;

    if (!rangeSelected) {
      e.preventDefault();
      e.stopPropagation();
      this.focusToEndInput();
      return;
    }

    if (!applied) {
      e.preventDefault();
      e.stopPropagation();
      this.focusToApply();
      return;
    }

    this.handleBlur();
  }

  handleFocus = () => {
    const { disabled, onFocus } = this.props;

    if (disabled) return false;

    this.setState({
      isOpen: true,
    }, onFocus);
  }

  handleBlur = () => {
    const { onBlur } = this.props;
    const { rangeSelected, applied } = this.state;

    if (!rangeSelected || !applied) return false;

    document.activeElement.blur();
    this.setState({
      isOpen: false,
    }, onBlur);
  }

  handleOpen = () => {
    const { disabled } = this.props;
    const { isOpen } = this.state;

    if (disabled) return false;

    if (isOpen) {
      this.handleBlur();
      return;
    }

    this.handleFocus();
  }

  handleClear = (e) => {
    const { disabled, onChange } = this.props;

    if (disabled) return false;

    e.stopPropagation();

    this.setState({
      from: null,
      to: null,
      enteredTo: null,
      rangeSelected: true,
      applied: true,
    }, () => {
      this.handleBlur();
      if (onChange && typeof onChange === 'function') {
        onChange({
          from: null,
          to: null,
        });
      }
    });
  }

  isSelectingFirstDay = (from, to, day) => {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  }

  handleDayClick = (day) => {
    const { from, to } = this.state;

    if (this.isSelectingFirstDay(from, to, day)) {
      this.setState({
        from: day,
        to: null,
        enteredTo: null,
        rangeSelected: false,
        applied: false,
      });
      return;
    }

    this.setState({
      to: day,
      enteredTo: day,
      rangeSelected: true,
      applied: false,
    });
  }

  handleDayMouseEnter = (day) => {
    const { from, to } = this.state;

    if (!this.isSelectingFirstDay(from, to, day)) {
      this.setState({
        enteredTo: day,
        rangeSelected: false,
        applied: false,
      });
    }
  }

  handleApply = () => {
    const { onChange } = this.props;
    const { from, to, rangeSelected } = this.state;

    if (!rangeSelected) {
      return this.focusToEndInput();
    }

    this.setState({
      applied: true,
    }, () => {
      this.handleBlur();
      if (onChange && typeof onChange === 'function') {
        onChange({
          from,
          to,
        });
      }
    });
  }

  handlePreset = (start, end) => {
    this.setState({
      from: start,
      to: end,
      enteredTo: end,
      rangeSelected: true,
      applied: true,
    }, this.handleApply);
  }

  handleCancel = () => {
    const { value, onCancel } = this.props;

    this.setState({
      from: value?.from,
      to: value?.to,
      enteredTo: value?.to,
      rangeSelected: true,
      applied: true,
    }, () => {
      this.handleBlur();
      if (onCancel && typeof onCancel === 'function') {
        onCancel();
      }
    });
  }

  render() {
    const {
      mini,
      clearable,
      disabled,
      open,
      value,
      label,
      placeholder,
      persistPlaceholder,

      tetherProps,
      getTetherProps,
      wrapperClassName,
      wrapperProps,
      dropdownWrapperClassName,
      dropdownWrapperProps,
      dropdownClassName,
      dropdownProps,
      dropdownSidebarProps,
      datePickerProps,

      renderBefore,
      renderAfter,
      renderLabel,
      renderValue,
      renderDropdownBefore,
      renderDropdownAfter,
      renderDropdownInputs,
      renderDropdownButtons,
      renderDropdownPresets,

      defaultPlaceholder,
      defaultStartPlaceholder,
      defaultEndPlaceholder,
      defaultDisplayFormat,
      defaultPresets,
    } = this.props;
    const {
      isOpen,
      from,
      to,
      enteredTo,
      rangeSelected,
    } = this.state;

    const displayValue = () => {
      if (persistPlaceholder || (!value?.from && !value?.to)) {
        return (
          <span className="date-value-placeholder">
            {placeholder || defaultPlaceholder}
          </span>
        );
      }

      if (!value?.from && !value?.to) {
        return null;
      }

      return (
        <React.Fragment>
          <span className="date-value-value-from">
            {value?.from ? format(value.from, defaultDisplayFormat) : defaultStartPlaceholder}
          </span>
          <span className="date-value-value-to">
            {value?.to ? format(value.to, defaultDisplayFormat) : defaultEndPlaceholder}
          </span>
        </React.Fragment>
      );
    };

    return (
      <TetherComponent
        {...getTetherProps(tetherProps)}
        renderTarget={(ref) => (
          <div
            ref={ref}
            className={`date-wrapper ${isOpen ? 'is-focused' : ''} ${mini ? 'is-mini' : ''} ${disabled ? 'is-disabled' : ''} ${rangeSelected ? 'is-rangeselected' : ''} ${wrapperClassName}`}
            {...wrapperProps}
          >
            <div ref={this.valueRef} onClick={this.handleOpen}>
              {renderBefore && renderBefore(this.getInternalState({ open, value }))}
              <div>
                {renderLabel ? renderLabel(this.getInternalState({
                  label,
                  open,
                  handleFocus: this.handleFocus,
                  handleBlur: this.handleBlur,
                })) : label && <label className="input-label">{label}</label>}
                {renderValue ? renderValue(this.getInternalState({
                  open,
                  value,
                  clearable,
                  displayValue,
                  placeholder,
                  defaultPlaceholder,
                  handleOpen: this.handleOpen,
                  handleFocus: this.handleFocus,
                  handleBlur: this.handleBlur,
                  handleClear: this.handleClear,
                })) : (
                  <div className="date-value">
                    <Icon className="date-value-icon" i="calendar" size={14} />
                    <span className="date-value-value">{displayValue()}</span>
                    <div className="date-value-icons">
                      {clearable && (
                        <Icon
                          className="date-value-clear"
                          title="Clear"
                          i="x-circle"
                          size={14}
                          onClick={this.handleClear}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
              {renderAfter && renderAfter(this.getInternalState({ open, value }))}
            </div>
          </div>
        )}
        renderElement={(ref) => isOpen && (
          <div
            ref={ref}
            className={`date-dropdown-wrapper ${isOpen ? 'is-focused' : ''} ${mini ? 'is-mini' : ''} ${disabled ? 'is-disabled' : ''} ${rangeSelected ? 'is-rangeselected' : ''} ${dropdownWrapperClassName}`}
            {...dropdownWrapperProps}
          >
            <div
              ref={this.dropdownRef}
              className={`date-dropdown ${dropdownClassName}`}
              {...dropdownProps}
            >
              {renderDropdownBefore && renderDropdownBefore(this.getInternalState({ open, value }))}
              <DayPicker
                firstDayOfWeek={1}
                numberOfMonths={2}
                month={from}
                selectedDays={[
                  from,
                  {
                    from,
                    to: enteredTo,
                  },
                ]}
                modifiers={{
                  start: from,
                  end: enteredTo,
                }}
                onDayClick={this.handleDayClick}
                onDayMouseEnter={this.handleDayMouseEnter}
                captionElement={Caption}
                {...datePickerProps}
              />
              <div className="date-dropdown-sidebar" {...dropdownSidebarProps}>
                {renderDropdownInputs
                  ? renderDropdownInputs(this.getInternalState({ open, value })) : (
                    <div className="date-dropdown-inputs">
                      <div className={`date-dropdown-inputs-from ${rangeSelected ? 'is-focused' : ''}`}>
                        <span>
                          {from
                            ? format(from, defaultDisplayFormat)
                            : defaultStartPlaceholder}
                        </span>
                      </div>
                      <i>-</i>
                      <div ref={this.endDatePickerRef} tabIndex={0} className={`date-dropdown-inputs-to ${!rangeSelected ? 'is-focused' : ''}`}>
                        <span>{to ? format(to, defaultDisplayFormat) : defaultEndPlaceholder}</span>
                      </div>
                    </div>
                  )}
                {renderDropdownPresets
                  ? renderDropdownPresets(this.getInternalState({ open, value })) : (
                    <div className="date-dropdown-presets">
                      {defaultPresets.map(({ text, start, end }) => {
                        const isSelected = (from && isSameDay(start, from))
                          && (to && isSameDay(end, to));
                        return (
                          <span
                            key={text}
                            className={isSelected ? 'is-selected' : ''}
                            onClick={() => this.handlePreset(start, end)}
                          >
                            {text}
                          </span>
                        );
                      })}
                    </div>
                  )}
                {renderDropdownButtons ? renderDropdownButtons(this.getInternalState({
                  open,
                  value,
                  handleApply: this.handleApply,
                  handleCancel: this.handleCancel,
                })) : (
                  <div className="date-dropdown-buttons">
                    <span className="date-dropdown-timezone">UTC</span>
                    <span className="date-dropdown-buttons-cancel" onClick={this.handleCancel}>Cancel</span>
                    <Button ref={this.applyButtonRef} mini onClick={this.handleApply} className={!rangeSelected ? 'is-disabled' : ''}>Apply</Button>
                  </div>
                )}
              </div>
              {renderDropdownAfter && renderDropdownAfter(this.getInternalState({ open, value }))}
            </div>
          </div>
        )}
      />
    );
  }
}

DatePicker.propTypes = {
  mini: PropTypes.bool,
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
  open: PropTypes.bool,
  value: PropTypes.shape({
    from: PropTypes.instanceOf(Date).isRequired,
    to: PropTypes.instanceOf(Date).isRequired,
  }).isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  persistPlaceholder: PropTypes.bool,

  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onCancel: PropTypes.func,

  tetherProps: PropTypes.shape(),
  getTetherProps: PropTypes.func,
  wrapperClassName: PropTypes.string,
  wrapperProps: PropTypes.shape(),
  dropdownWrapperClassName: PropTypes.string,
  dropdownWrapperProps: PropTypes.shape(),
  dropdownClassName: PropTypes.string,
  dropdownProps: PropTypes.shape(),
  dropdownSidebarProps: PropTypes.shape(),
  datePickerProps: PropTypes.shape(),

  renderBefore: PropTypes.elementType,
  renderAfter: PropTypes.elementType,
  renderLabel: PropTypes.elementType,
  renderValue: PropTypes.elementType,
  renderDropdownBefore: PropTypes.elementType,
  renderDropdownAfter: PropTypes.elementType,
  renderDropdownInputs: PropTypes.elementType,
  renderDropdownButtons: PropTypes.elementType,
  renderDropdownPresets: PropTypes.elementType,

  defaultPlaceholder: PropTypes.string,
  defaultStartPlaceholder: PropTypes.string,
  defaultEndPlaceholder: PropTypes.string,
  defaultDisplayFormat: PropTypes.string,
  defaultPresets: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      start: PropTypes.instanceOf(Date),
      end: PropTypes.instanceOf(Date),
    }),
  ),
};

DatePicker.defaultProps = {
  open: false,
  mini: false,
  clearable: false,
  disabled: false,
  persistPlaceholder: false,

  label: null,
  placeholder: null,
  onFocus: null,
  onBlur: null,
  onCancel: null,

  renderBefore: null,
  renderAfter: null,
  renderLabel: null,
  renderValue: null,
  renderDropdownBefore: null,
  renderDropdownAfter: null,
  renderDropdownInputs: null,
  renderDropdownButtons: null,
  renderDropdownPresets: null,

  tetherProps: {
    attachment: 'top left',
    targetAttachment: 'bottom left',
    constraints: [
      {
        to: 'window',
        attachment: 'together',
        pin: true,
      },
    ],
  },
  getTetherProps: (props) => props,
  wrapperProps: {},
  dropdownSidebarProps: {},
  dropdownWrapperProps: {},
  dropdownProps: {},
  datePickerProps: {},

  wrapperClassName: '',
  dropdownWrapperClassName: '',
  dropdownClassName: '',

  defaultPlaceholder: 'Select a Date',
  defaultStartPlaceholder: 'Select Start Date',
  defaultEndPlaceholder: 'Select End Date',
  defaultDisplayFormat: 'MMM dd, yyyy',
  defaultPresets: [
    {
      text: 'Today',
      start: new Date(),
      end: new Date(),
    },
    {
      text: 'Yesterday',
      start: subDays(new Date(), 1),
      end: subDays(new Date(), 1),
    },
    {
      text: 'Last 7 Days',
      start: subDays(new Date(), 6),
      end: new Date(),
    },
    {
      text: 'Last 30 Days',
      start: subDays(new Date(), 29),
      end: new Date(),
    },
    {
      text: 'This Month',
      start: setDate(new Date(), 1),
      end: new Date(),
    },
    {
      text: 'Last Month',
      start: setDate(subMonths(new Date(), 1), 1),
      end: endOfMonth(subMonths(new Date(), 1)),
    },
  ],
};

export default DatePicker;
