import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon';

import './Navigation.css';

import intIcon from '../../assets/icon-white.svg';

function Navigation({
  logo,
  create,
  topMenuItems,
  bottomMenuItems,
  user,

  renderBefore,
  renderAfter,
  renderTopBefore,
  renderTopAfter,
  renderTopMenuBefore,
  renderTopMenuAfter,
  renderBottomBefore,
  renderBottomAfter,
  renderBottomMenuBefore,
  renderBottomMenuAfter,
}) {
  const [userMenuOpen, setUserMenuOpen] = React.useState(false);
  const dropdownRef = React.useRef();

  const handleOutsideClick = (e) => {
    if (dropdownRef.current.contains(e.target)) return;

    setUserMenuOpen(false);
  };

  React.useEffect(() => {
    if (userMenuOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
    // eslint-disable-next-line
  }, [userMenuOpen]);

  return (
    <header className="navigation">
      {renderBefore && renderBefore()}
      <div className="navigation-top">
        {renderTopBefore && renderTopBefore()}
        {logo}
        {topMenuItems && (
          <nav className="navigation-menu">
            {renderTopMenuBefore && renderTopMenuBefore()}
            {create && (
              <create.component
                className="navigation-menu-item is-create"
                {...create.linkProps}
              >
                {create.renderItemBefore && create.renderItemBefore()}
                <Icon i={create.icon} size={24} {...create.iconProps} />
                <span {...create.titleProps}>{create.title}</span>
                {create.renderItemAfter && create.renderItemAfter()}
              </create.component>
            )}
            {topMenuItems.map(({
              seperator,
              component: Component,
              linkProps,
              icon,
              iconProps,
              title,
              titleProps,
              renderItemBefore,
              renderItemAfter,
            }, index) => {
              if (seperator) {
                return <span key={`sep-${index}`} className="navigation-menu-seperator" />;
              }
              return (
                <Component
                  key={`item-${index}`}
                  className="navigation-menu-item"
                  {...linkProps}
                >
                  {renderItemBefore && renderItemBefore()}
                  <Icon i={icon} size={18} {...iconProps} />
                  <span {...titleProps}>{title}</span>
                  {renderItemAfter && renderItemAfter()}
                </Component>
              );
            })}
            {renderTopMenuAfter && renderTopMenuAfter()}
          </nav>
        )}
        {renderTopAfter && renderTopAfter()}
      </div>

      <div className="navigation-bottom">
        {renderBottomBefore && renderBottomBefore()}
        {bottomMenuItems && (
          <nav className="navigation-menu">
            {renderBottomMenuBefore && renderBottomMenuBefore()}
            {bottomMenuItems.map(({
              seperator,
              component: Component,
              linkProps,
              icon,
              iconProps,
              title,
              titleProps,
              renderItemBefore,
              renderItemAfter,
            }, index) => {
              if (seperator) {
                return <span key={`sep-${index}`} className="navigation-menu-seperator" />;
              }
              return (
                <Component
                  key={`item-${index}`}
                  className="navigation-menu-item"
                  {...linkProps}
                >
                  {renderItemBefore && renderItemBefore()}
                  <Icon i={icon} size={18} {...iconProps} />
                  <span {...titleProps}>{title}</span>
                  {renderItemAfter && renderItemAfter()}
                </Component>
              );
            })}
            {renderBottomMenuAfter && renderBottomMenuAfter()}
          </nav>
        )}
        {renderBottomAfter && renderBottomAfter()}
      </div>
      {user && (
        <div className="navigation-user">
          <div
            className="navigation-user-initial"
            onClick={() => setUserMenuOpen(!userMenuOpen)}
            {...user.initialProps}
          >
            {user.name[0]}
          </div>
          <div
            ref={dropdownRef}
            className={`navigation-user-dropdown ${userMenuOpen ? 'is-open' : ''}`}
            {...user.dropdownProps}
          >
            <div className="navigation-user-info">
              <div className="navigation-user-info-name">{user.name}</div>
              <div className="navigation-user-info-email">{user.email}</div>
            </div>
            {user.dropdownItems && (
              <div className="navigation-user-dropdown-menu">
                {user.dropdownItems.map(({
                  seperator,
                  component: Component,
                  linkProps,
                  icon,
                  iconProps,
                  title,
                  titleProps,
                  renderItemBefore,
                  renderItemAfter,
                }, index) => {
                  if (seperator) {
                    return <span key={`sep-${index}`} className="navigation-user-dropdown-menu-seperator" />;
                  }
                  return (
                    <Component
                      key={`item-${index}`}
                      className="navigation-user-dropdown-menu-item"
                      {...linkProps}
                    >
                      {renderItemBefore && renderItemBefore()}
                      <Icon i={icon} size={15} {...iconProps} />
                      <span {...titleProps}>{title}</span>
                      {renderItemAfter && renderItemAfter()}
                    </Component>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      )}
      {renderAfter && renderAfter()}
    </header>
  );
}

Navigation.propTypes = {
  logo: PropTypes.node,
  create: PropTypes.shape({
    component: PropTypes.elementType,
    linkProps: PropTypes.shape(),
    icon: PropTypes.string,
    iconProps: PropTypes.shape(),
    title: PropTypes.node,
    titleProps: PropTypes.shape(),
    renderItemBefore: PropTypes.func,
    renderItemAfter: PropTypes.func,
  }),
  topMenuItems: PropTypes.arrayOf(
    PropTypes.shape({
      seperator: PropTypes.bool,
      component: PropTypes.elementType,
      linkProps: PropTypes.shape(),
      icon: PropTypes.string,
      iconProps: PropTypes.shape(),
      title: PropTypes.node,
      titleProps: PropTypes.shape(),
      renderItemBefore: PropTypes.func,
      renderItemAfter: PropTypes.func,
    }),
  ),
  bottomMenuItems: PropTypes.arrayOf(
    PropTypes.shape({
      seperator: PropTypes.bool,
      component: PropTypes.elementType,
      linkProps: PropTypes.shape(),
      icon: PropTypes.string,
      iconProps: PropTypes.shape(),
      title: PropTypes.node,
      titleProps: PropTypes.shape(),
      renderItemBefore: PropTypes.func,
      renderItemAfter: PropTypes.func,
    }),
  ),
  user: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    initialProps: PropTypes.shape(),
    dropdownProps: PropTypes.shape(),
    dropdownItems: PropTypes.arrayOf(
      PropTypes.shape({
        seperator: PropTypes.bool,
        component: PropTypes.elementType,
        linkProps: PropTypes.shape(),
        icon: PropTypes.string,
        iconProps: PropTypes.shape(),
        title: PropTypes.node,
        titleProps: PropTypes.shape(),
        renderItemBefore: PropTypes.func,
        renderItemAfter: PropTypes.func,
      }),
    ),
  }),
  renderBefore: PropTypes.func,
  renderAfter: PropTypes.func,
  renderTopBefore: PropTypes.func,
  renderTopAfter: PropTypes.func,
  renderTopMenuBefore: PropTypes.func,
  renderTopMenuAfter: PropTypes.func,
  renderBottomBefore: PropTypes.func,
  renderBottomAfter: PropTypes.func,
  renderBottomMenuBefore: PropTypes.func,
  renderBottomMenuAfter: PropTypes.func,
};

Navigation.defaultProps = {
  logo: (
    <a href="/" className="navigation-logo">
      <img src={intIcon} alt="Interceptd Icon" />
    </a>
  ),
  create: {
    component: 'a',
    linkProps: {
      href: '',
    },
    icon: 'plus',
    title: 'New Campaign',
  },
  topMenuItems: [
    {
      component: 'a',
      linkProps: {
        href: '',
      },
      icon: 'list',
      title: 'Campaigns',
    },
    {
      component: 'a',
      linkProps: {
        href: '',
      },
      icon: 'activity',
      title: 'Daily Performance',
    },
    {
      component: 'a',
      linkProps: {
        href: '',
      },
      icon: 'pie-chart',
      title: 'Reports',
    },
    {
      component: 'a',
      linkProps: {
        href: '',
      },
      icon: 'filter',
      title: 'Event Analytics',
    },
    {
      seperator: true,
    },
    {
      component: 'a',
      linkProps: {
        href: '',
      },
      icon: 'heart',
      title: 'Fraud Health Assessment',
    },
  ],
  bottomMenuItems: [
    {
      component: 'a',
      linkProps: {
        href: '',
      },
      icon: 'settings',
      title: 'Settings',
    },
    {
      component: 'a',
      linkProps: {
        href: 'https://intercom.help/interceptd/en/',
        target: '_blank',
      },
      icon: 'help-circle',
      title: 'Help',
    },
  ],
  user: {
    name: 'Interceptd User',
    email: 'user@interceptd.com',
    initialProps: {},
    dropdownProps: {},
    dropdownItems: [
      {
        component: 'a',
        linkProps: {
          href: '',
        },
        icon: 'arrow-up-right',
        title: 'Switch to Influencer',
      },
      {
        seperator: true,
      },
      {
        component: 'a',
        linkProps: {
          href: '',
        },
        icon: 'settings',
        title: 'Settings',
      },
      {
        component: 'a',
        linkProps: {
          href: '',
        },
        icon: 'help-circle',
        title: 'Help',
      },
      {
        seperator: true,
      },
      {
        component: 'a',
        linkProps: {
          href: '',
        },
        icon: 'log-out',
        title: 'Logout',
      },
    ],
  },
  renderBefore: () => null,
  renderAfter: () => null,
  renderTopBefore: () => null,
  renderTopAfter: () => null,
  renderTopMenuBefore: () => null,
  renderTopMenuAfter: () => null,
  renderBottomBefore: () => null,
  renderBottomAfter: () => null,
  renderBottomMenuBefore: () => null,
  renderBottomMenuAfter: () => null,
};

export default Navigation;
