import React from 'react';
import PropTypes from 'prop-types';

export default function Caption({
  date,
  classNames,
  onClick,
  localeUtils,
  locale,
}) {
  const [key, setKey] = React.useState(Math.random().toString(36).substr(2, 9));
  const text = localeUtils.formatMonthTitle(date, locale);

  React.useEffect(() => {
    setKey(Math.random().toString(36).substr(2, 9));
  }, [text]);

  return (
    <div key={key} className={classNames.caption} role="heading">
      <div onClick={onClick}>{text}</div>
    </div>
  );
}

Caption.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  classNames: PropTypes.shape().isRequired,
  onClick: PropTypes.func,
  localeUtils: PropTypes.shape().isRequired,
  locale: PropTypes.string.isRequired,
};

Caption.defaultProps = {
  onClick: () => null,
};
