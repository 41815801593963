import React from 'react';
import PropTypes from 'prop-types';

import './Loader.css';

/**
 * @name Loader
 *
 * color variables:
 *  ---loaderColor
 *  ---loaderMiniColor
 */
const Loader = ({ mini, size, weight }) => {
  if (mini) {
    return (
      <div
        className="mini-loader"
        style={{
          '--size': `${size}px`,
          '--weight': `${weight}px`,
        }}
      />
    );
  }
  return (
    <div className="loader">
      <span className="x" />
      <span className="y" />
      <span className="z" />

      <span className="a" />
      <span className="b" />
      <span className="c" />
    </div>
  );
};

Loader.propTypes = {
  mini: PropTypes.bool,
  size: PropTypes.number,
  weight: PropTypes.number,
};

Loader.defaultProps = {
  mini: false,
  size: 30,
  weight: 3,
};

export default Loader;
