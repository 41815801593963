import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon';

import './Checkbox.css';

const Checkbox = ({ id, disabled, ...rest }) => (
  <label htmlFor={id} className={`custom-checkbox ${disabled ? 'disabled' : ''}`}>
    <input id={id} name={id} type="checkbox" disabled={disabled} {...rest} />
    <span>
      <Icon i={disabled ? 'slash' : 'check'} size={16} />
    </span>
  </label>
);

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  disabled: false,
};

export default Checkbox;
