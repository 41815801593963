import React from 'react';
import PropTypes from 'prop-types';

import icon from '../../assets/icon-gray.svg';

import './StackedAvatars.css';

const StackedAvatars = ({ avatars }) => {
  if (avatars.length === 0) {
    return null;
  }

  return (
    <div className="stacked-avatars">
      {avatars.map((avatar, index) => {
        if (index > 3) {
          return null;
        }

        if (index < 3) {
          return (
            <div className="avatar" key={index}>
              <img src={avatar?.src || icon} alt={`${avatar?.alt}`} />
            </div>
          );
        }

        return (
          <div className="avatar" key={index}>
            <span>{`+${avatars.length - 3}`}</span>
          </div>
        );
      })}
    </div>
  );
};

StackedAvatars.propTypes = {
  avatars: PropTypes.arrayOf(PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.alt,
  })).isRequired,
};

export default StackedAvatars;
