import React from 'react';
import PropTypes from 'prop-types';

import './Button.css';

const Button = React.forwardRef(({
  component: Component,
  bgColor,
  block,
  loading,
  mini,
  className,
  children,
  onClick,
  ...rest
}, ref) => {
  let buttonClass = `button is-${bgColor} ${className}`;

  if (block) {
    buttonClass += ' is-block';
  }

  if (loading) {
    buttonClass += ' is-loading';
  }

  if (mini) {
    buttonClass += ' is-mini';
  }

  return (
    <Component
      ref={ref}
      className={buttonClass}
      onClick={!loading ? onClick : null}
      {...rest}
    >
      {children}
    </Component>
  );
});

Button.propTypes = {
  component: PropTypes.elementType,
  className: PropTypes.string,
  bgColor: PropTypes.string,
  type: PropTypes.string,
  block: PropTypes.bool,
  loading: PropTypes.bool,
  mini: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

Button.defaultProps = {
  component: 'button',
  className: '',
  bgColor: 'primary',
  block: false,
  mini: false,
  loading: false,
  type: 'button',
  onClick: () => null,
};

export default Button;

// TODO: Add icon prop
