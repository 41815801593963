import { toast } from 'react-toastify';

import { getParameterByName } from './transform';

export function debounce(fn, delay) {
  let timer = null;
  return () => {
    const context = this;
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, delay);
  };
}

export const isStoreURL = (url) => {
  let store;
  let packname;

  if ((url.indexOf('itunes') !== -1 || url.indexOf('apps.apple') !== -1)) {
    store = 'ios';
  } else if (url.indexOf('google') !== -1) {
    store = 'play';
  }

  if (store === 'play') {
    packname = getParameterByName('id', url);
  } else if (store === 'ios') {
    // eslint-disable-next-line
    packname = (new RegExp('/id(\\d+)')).exec(url.split('?')[0])[1]
  }

  return url && store && packname;
};

export const isURL = (str) => {
  const pattern = new RegExp('^(https?:\\/\\/)?' // protocol
    + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name
    + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
    + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
    + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
    + '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(str);
};

// eslint-disable-next-line consistent-return
export const decodeToken = (token = '') => {
  try {
    // eslint-disable-next-line
    const buff = new Buffer(token.split('.')[1], 'base64');
    return JSON.parse(buff.toString('ascii'));
  } catch (err) {
    toast.error('Something went wrong');
  }
};

export const versionCompare = (v1, v2, options) => {
  const lexicographical = options && options.lexicographical;
  const zeroExtend = options && options.zeroExtend;
  let v1parts = v1.split('.');
  let v2parts = v2.split('.');

  const isValidPart = (x) => (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);

  if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
    return NaN;
  }

  if (zeroExtend) {
    while (v1parts.length < v2parts.length) v1parts.push('0');
    while (v2parts.length < v1parts.length) v2parts.push('0');
  }

  if (!lexicographical) {
    v1parts = v1parts.map(Number);
    v2parts = v2parts.map(Number);
  }

  for (let i = 0; i < v1parts.length; i += 1) {
    if (v2parts.length === i) {
      return 1;
    }

    if (v1parts[i] === v2parts[i]) {
      // eslint-disable-next-line no-continue
      continue;
    } else if (v1parts[i] > v2parts[i]) {
      return 1;
    } else {
      return -1;
    }
  }

  if (v1parts.length !== v2parts.length) {
    return -1;
  }

  return 0;
};

export const getStoreUrl = (appId) => {
  if (isStoreURL(appId)) return appId;

  const type = (
    (appId.startsWith('id') && appId.length >= 11 && !Number.isNaN(appId.replace('id', '')))
    || (!Number.isNaN(appId) && appId.length >= 9)
  )
    ? 'ios'
    : 'android';

  const url = type === 'ios'
    ? `https://itunes.apple.com/app/${!Number.isNaN(appId) ? 'id' : ''}${appId}`
    : `https://play.google.com/store/apps/details?id=${appId}`;

  return url;
};

export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const parseArray = (arr) => {
  let result;
  try {
    result = typeof arr === 'string'
      ? JSON.parse(arr)
      : arr;
  } catch (err) {
    result = Array.isArray(arr)
      ? arr
      : [];
  }
  return result || [];
};

export const getQueryParams = (name, url) => {
  const urlParams = new URLSearchParams(url || window.location.search);
  return urlParams.get(name);
};

export const renderAlarmName = (name = '') => {
  switch (name) {
    case 'Ad Stacking': return 'Click Stuffing';
    case 'ADS': return 'CSF';
    default: return name.replace(/_IPQS/g, '');
  }
};

export const shadowText = ({ str, alt = 'Example', isShadow = false }) => {
  if (isShadow) return alt;
  return str;
};
