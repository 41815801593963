import React from 'react';
import PropTypes from 'prop-types';

import './Breadcrumbs.css';

const Crumb = ({ children }) => (
  <div className="breadcrumbs-crumb">
    {children}
  </div>
);

Crumb.propTypes = {
  children: PropTypes.node.isRequired,
};

export const Breadcrumbs = ({ children }) => (
  <div className="breadcrumbs">
    {children}
  </div>
);

Breadcrumbs.propTypes = {
  children: PropTypes.node.isRequired,
};

Breadcrumbs.Crumb = Crumb;

export default Breadcrumbs;
