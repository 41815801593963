import React from 'react';
import PropTypes from 'prop-types';

import './SecondaryNav.css';

export const NavItem = ({ children }) => (
  <div className="secondary-nav-item">
    {children}
  </div>
);

NavItem.propTypes = {
  children: PropTypes.node.isRequired,
};

export const SecondaryNav = ({ children }) => (
  <nav className="secondary-nav">
    {children}
  </nav>
);

SecondaryNav.NavItem = NavItem;

SecondaryNav.propTypes = {
  children: PropTypes.node.isRequired,
};


export default SecondaryNav;
