import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './Timeline.css';

const Timeline = ({ children, ...rest }) => (
  <div className="timeline" {...rest}>
    <div className="timeline-inner">{children}</div>
  </div>
);

Timeline.propTypes = {
  children: PropTypes.node,
};

Timeline.defaultProps = {
  children: null,
};

const TimelineItem = ({ time, changes, ...rest }) => (
  <div className="timeline-item" {...rest}>
    <div className="timeline-item-time">{time}</div>
    <div className="timeline-item-content">
      {changes.map((item, index) => <TimelineItemContent key={`content-${index}`} item={item} />)}
    </div>
  </div>
);

TimelineItem.propTypes = {
  time: PropTypes.string,
  changes: PropTypes.arrayOf(PropTypes.shape()),
};

TimelineItem.defaultProps = {
  time: null,
  changes: [],
};

const TimelineItemContent = ({ item }) => {
  const [more, setMore] = useState(false);

  return (
    <React.Fragment>
      <div className="timeline-item-title">
        {`${item.title} changed`}
      </div>
      <div className={`timeline-item-summary ${(item.before && item.before.length) > 100 || (item.after && item.after.length) > 100 ? 'long' : ''} ${more ? 'show-more' : ''}`}>
        <div className="timeline-item-summary-inner">
          {item.before ? 'From' : 'Set'}
          {item.before && <span className="timeline-item-before">{Array.isArray(item.before) ? item.before.join(', ') : item.before}</span>}
          {' to '}
          <span className="timeline-item-after">{Array.isArray(item.after) ? item.after.join(', ') : item.after}</span>
        </div>
        <span className="timeline-item-more" onClick={() => setMore(!more)}>
          {more ? 'Show less' : 'Show more'}
        </span>
      </div>
    </React.Fragment>
  );
};

TimelineItemContent.propTypes = {
  item: PropTypes.shape({
    key: PropTypes.string,
    title: PropTypes.string,
    before: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.string,
    ]),
    after: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.string,
    ]),
  }),
};

TimelineItemContent.defaultProps = {
  item: {},
};

Timeline.TimelineItem = TimelineItem;
export default Timeline;
