import React from 'react';
import PropTypes from 'prop-types';

import { copyTextToClipboard } from '../../utils/file';

import './Copy.css';

const Copy = ({
  value,
  onCopy,
  children,
  ...rest
}) => {
  const handleClick = () => {
    copyTextToClipboard(value);
    onCopy();
  };

  return (
    <div className="copy-button" onClick={handleClick} {...rest}>
      {children}
    </div>
  );
};

Copy.propTypes = {
  value: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onCopy: PropTypes.func,
};

Copy.defaultProps = {
  onCopy: () => null,
};

export default Copy;
