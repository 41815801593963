import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import Icon from '../Icon';
import Button from '../Button';

import './Modal.css';

const Modal = ({
  open,
  portal,
  hideHeader,
  hideFooter,
  icon,
  title,
  subtitle,
  children,

  onBlur,
  onAccept,
  onCancel,

  wrapperClassName,
  wrapperProps,
  modalClassName,
  modalProps,
  cancelProps,
  acceptProps,

  renderWrapperBefore,
  renderWrapperAfter,
  renderBefore,
  renderAfter,
  renderHeader,
  renderContent,
  renderFooter,

  defaultCancelText,
  defaultAcceptText,
}) => {
  const handleBlur = () => {
    if (onBlur) onBlur();
    if (onCancel) onCancel();
  };

  const renderModal = open && (
    <div onClick={handleBlur} className={`modal-wrapper ${open ? 'is-focused' : ''} ${wrapperClassName || ''}`} {...wrapperProps}>
      {renderWrapperBefore && renderWrapperBefore()}
      <div
        onClick={(e) => e.stopPropagation()}
        className={`modal ${modalClassName || ''}`}
        {...modalProps}
      >
        {renderBefore && renderBefore()}
        {renderHeader ? renderHeader({ icon, title, subtitle }) : !hideHeader && (
          <div className="modal-header">
            {icon && <Icon className="modal-header-icon" i={icon} size={24} />}
            <div className="modal-header-title-wrapper">
              {title && <div className="modal-header-title">{title}</div>}
              {subtitle && <div className="modal-header-subtitle">{subtitle}</div>}
            </div>
          </div>
        )}
        {renderContent ? renderContent({ children }) : children && (
          <div className="modal-content">
            {children}
          </div>
        )}
        {renderFooter ? renderFooter({ onAccept, onCancel }) : !hideFooter && (
          <div className="modal-footer">
            <Button
              mini
              bgColor="transparent"
              onClick={onCancel}
              {...cancelProps}
            >
              {defaultCancelText}
            </Button>
            <Button
              mini
              bgColor="primary"
              onClick={onAccept}
              {...acceptProps}
            >
              {defaultAcceptText}
            </Button>
          </div>
        )}
        {renderAfter && renderAfter()}
      </div>
      {renderWrapperAfter && renderWrapperAfter()}
    </div>
  );

  if (portal) {
    return ReactDOM.createPortal(renderModal, document.body);
  }

  return renderModal;
};

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  portal: PropTypes.bool,
  hideHeader: PropTypes.bool,
  hideFooter: PropTypes.bool,
  icon: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,

  onBlur: PropTypes.func,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,

  wrapperClassName: PropTypes.string,
  wrapperProps: PropTypes.shape(),
  modalClassName: PropTypes.string,
  modalProps: PropTypes.shape(),
  cancelProps: PropTypes.shape(),
  acceptProps: PropTypes.shape(),

  renderWrapperBefore: PropTypes.elementType,
  renderWrapperAfter: PropTypes.elementType,
  renderBefore: PropTypes.elementType,
  renderAfter: PropTypes.elementType,
  renderHeader: PropTypes.elementType,
  renderContent: PropTypes.elementType,
  renderFooter: PropTypes.elementType,

  defaultCancelText: PropTypes.string,
  defaultAcceptText: PropTypes.string,
};

Modal.defaultProps = {
  open: false,
  portal: true,
  icon: 'info',

  wrapperProps: {},
  modalProps: {},
  cancelProps: {},
  acceptProps: {},

  defaultCancelText: 'Cancel',
  defaultAcceptText: 'Save',
};

export default Modal;
