import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon';
import Tooltip from '../Tooltip';

import useOutClick from '../../hooks/use-out-click';

import './Info.css';

export default function Info({
  i,
  size,
  iconProps,
  children,
  tooltipProps,
}) {
  const [open, setOpen] = React.useState(false);
  const iconRef = React.useRef();
  const tooltipRef = React.useRef();

  useOutClick([iconRef, tooltipRef], () => {
    setOpen(false);
  }, open, [open]);

  return (
    <Tooltip
      openOnHover={false}
      isOpen={open}
      tooltip={<span ref={tooltipRef}>{children}</span>}
      position="right"
      tooltipProps={{
        style: {
          whiteSpace: 'normal',
          maxWidth: 350,
          lineHeight: 1.5,
          pointerEvents: 'all',
          userSelect: 'auto',
        },
      }}
      {...tooltipProps}
    >
      <Icon
        ref={iconRef}
        className={`info-trigger ${open ? 'is-active' : ''}`}
        style={{
          '--size': size,
        }}
        i={i}
        size={size}
        onClick={(e) => {
          e.stopPropagation();
          setOpen((prevOpen) => !prevOpen);
        }}
        {...iconProps}
      />
    </Tooltip>
  );
}

Info.propTypes = {
  i: PropTypes.string,
  size: PropTypes.number,
  iconProps: PropTypes.shape(),
  children: PropTypes.node,
  tooltipProps: PropTypes.shape(),
};

Info.defaultProps = {
  i: 'help-circle',
  size: 13,
  iconProps: null,
  children: '',
  tooltipProps: null,
};
