import React from 'react';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-autosize-textarea';

import './Textarea.css';

const Textarea = ({
  className,
  disabled,
  value,
  label,
  id,
  name,
  error,
  warning,
  autosize,
  inputRef,

  onFocus,
  onBlur,

  renderBefore,
  renderAfter,

  ...rest
}) => {
  const [focused, setFocused] = React.useState(false);

  const handleFocus = (event) => {
    setFocused(true);
    if (onFocus) onFocus(event);
  };

  const handleBlur = (event) => {
    setFocused(false);
    if (onBlur) onBlur(event);
  };

  return (
    <div className={`input-wrapper textarea-wrapper ${className || ''} ${error ? 'error' : ''} ${warning ? 'warning' : ''} ${disabled ? 'disabled' : ''} ${focused ? 'focused' : ''}`}>
      {renderBefore && renderBefore(value)}
      {label && <label className="input-label" htmlFor={id || name}>{label}</label>}
      {autosize ? (
        <TextareaAutosize
          className="textarea autosize"
          id={id || name}
          name={name}
          ref={inputRef}
          disabled={disabled}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={value}
          {...rest}
        />
      ) : (
        <textarea
          className="textarea"
          id={id || name}
          name={name}
          ref={inputRef}
          disabled={disabled}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={value}
          {...rest}
        />
      )}
      {renderAfter && renderAfter(value)}
    </div>
  );
};

Textarea.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.bool,
  warning: PropTypes.bool,
  autosize: PropTypes.bool,
  inputRef: PropTypes.node.isRequired,

  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  renderBefore: PropTypes.func,
  renderAfter: PropTypes.func,
};

Textarea.defaultProps = {
  className: '',
  value: '',
  label: '',
  id: '',
  name: '',
  disabled: false,
  error: false,
  warning: false,
  autosize: false,
  onFocus: null,
  onBlur: null,
  renderBefore: null,
  renderAfter: null,
};

export default Textarea;
