import React from 'react';
import PropTypes from 'prop-types';

import './ShadowImage.css';

const ShadowImage = ({
  className,
  src,
  alt,
  isShadow,
  ...rest
}) => (
  <img
    className={`${className} ${isShadow ? 'shadow-image' : ''}`}
    src={src}
    alt={alt}
    {...rest}
  />
);

ShadowImage.propTypes = {
  className: PropTypes.string,
  isShadow: PropTypes.bool,
  alt: PropTypes.string,
  src: PropTypes.string.isRequired,
};

ShadowImage.defaultProps = {
  alt: '',
  className: '',
  isShadow: false,
};

export default ShadowImage;
