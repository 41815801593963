import React from 'react';
import PropTypes from 'prop-types';

import './Input.css';

function useCombinedRefs(...refs) {
  const targetRef = React.useRef();

  React.useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return;

      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else {
        // eslint-disable-next-line
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
}

const Input = React.forwardRef(({
  type,
  label,
  id, name,
  error,
  warning,
  className,
  focused,
  disabled,
  required,

  onFocus,
  onBlur,

  ...rest
}, ref) => {
  const inputRef = React.useRef();
  const combinedRef = useCombinedRefs(ref, inputRef);
  const [focus, setFocus] = React.useState(focused);

  React.useEffect(() => {
    setFocus(focused);
    if (focused) combinedRef.current.focus();
  }, [focused, combinedRef]);

  React.useEffect(() => {
    if (type !== 'number') return () => {};
    const el = combinedRef.current;
    const handleWheel = (e) => e.preventDefault();
    el.addEventListener('wheel', handleWheel);

    return () => {
      el.removeEventListener('wheel', handleWheel);
    };
  }, [type, combinedRef]);

  const handleFocus = (event) => {
    setFocus(true);
    onFocus(event);
  };

  const handleBlur = (event) => {
    setFocus(false);
    onBlur(event);
  };

  return (
    <div className={`
      input-wrapper
      ${className || ''}
      ${error ? 'error' : ''}
      ${warning ? 'warning' : ''}
      ${disabled ? 'disabled' : ''}
      ${focus ? 'focused' : ''}
      ${required ? 'required' : ''}
      ${rest.value && rest.value !== '' ? 'filled' : ''}
    `}
    >
      {label && (
        <label className="input-label" htmlFor={id || name}>{label}</label>
      )}
      <input
        className="input"
        id={id || name}
        name={name}
        ref={combinedRef}
        type={type}
        disabled={disabled}
        required={required}
        onFocus={handleFocus}
        onBlur={handleBlur}
        {...rest}
      />
      <span className="input-info">{error || warning}</span>
    </div>
  );
});

Input.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.string,
  warning: PropTypes.string,
  focused: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  className: PropTypes.string,

  onFocus: PropTypes.func,
  onBlur: PropTypes.func,

  spellCheck: PropTypes.bool,
  autoComplete: PropTypes.string,
};

Input.defaultProps = {
  type: 'text',
  label: null,
  id: null,
  name: '',
  error: null,
  warning: null,
  focused: false,
  disabled: false,
  required: false,
  className: '',

  onFocus: () => null,
  onBlur: () => null,

  spellCheck: false,
  autoComplete: 'nope',
};

export default Input;
