import React from 'react';
import PropTypes from 'prop-types';

import './Alert.css';

const titleMap = {
  warning: 'Warning',
  error: 'Error',
  info: 'Info',
  success: 'Success',
  cta: 'CTA',
};

const Alert = ({
  type,
  title,
  showTitle,
  showDot,
  button,
  children,
}) => (
  <span className={`alert-box alert-box-${type}`}>
    {showDot && (
      <div className="dot-wrapper">
        <div className="dot-icon" />
      </div>
    )}
    <div className="alert-body">
      {showTitle && <span className="title">{title || titleMap[type]}</span>}
      <div className="body-wrapper">
        <span>{children}</span>
        {button}
      </div>
    </div>
  </span>
);

Alert.propTypes = {
  type: PropTypes.oneOf([
    'warning',
    'error',
    'info',
    'success',
    'cta',
  ]),
  showTitle: PropTypes.bool,
  showDot: PropTypes.bool,
  button: PropTypes.element,
  title: PropTypes.node,
  children: PropTypes.node.isRequired,
};

Alert.defaultProps = {
  type: 'info',
  showTitle: true,
  showDot: true,
  button: null,
  title: null,
};

export default Alert;
