import React from 'react';
import Humanize from 'humanize-plus';
import PropTypes from 'prop-types';

import Tooltip from '../Tooltip';

import './FraudGraph.css';

const FraudGraph = (props) => {
  const {
    showFlagged,
    showRejected,
    showPercents,
    showUnique,
    title,
    noDataMessage,
    loading,
    data: {
      total = 0,
      clean = 0,
      flagged = 0,
      rejected = 0,
      unique = 0,
      infoContent = null,
    },
  } = props;

  let rejectedPercent = (+rejected / +total) * 100;
  let flaggedPercent = (+flagged / +total) * 100;
  let cleanPercent = (+clean / +total) * 100;
  let noData = false;

  if (loading || (rejected === 0 && flagged === 0 && clean === 0)) {
    rejectedPercent = noDataMessage ? 30 : 10;
    flaggedPercent = noDataMessage ? 60 : 10;
    cleanPercent = 10;
    noData = true;
  }

  const tooltipSpacing = {
    style: {
      transform: 'translateY(20px)',
    },
  };

  return (
    <div className={`fraudgraph ${loading ? 'fraudgraph-loading' : ''} ${noData ? 'fraudgraph-nodata' : ''}`}>
      <div className="fraudgraph-bars">
        {showRejected && (
          <div
            className="fraudgraph-bar fraudgraph-rejected"
            style={{ '--percent': `${rejectedPercent}%` }}
          >
            <Tooltip
              tooltip={Humanize.intComma(rejected)}
              tooltipProps={tooltipSpacing}
            >
              <span className="fraudgraph-bar-percent">
                {`${rejectedPercent.toFixed(0)}%`}
              </span>
              <span className="fraudgraph-bar-count">
                {showPercents ? `${rejectedPercent.toFixed(0)}%` : Humanize.compactInteger(rejected, 1)}
              </span>
            </Tooltip>
          </div>
        )}
        {showFlagged && (
          <div
            className="fraudgraph-bar fraudgraph-flagged"
            style={{ '--percent': `${flaggedPercent}%` }}
          >
            <Tooltip
              tooltip={Humanize.intComma(flagged)}
              tooltipProps={tooltipSpacing}
            >
              <span className="fraudgraph-bar-percent">
                {`${flaggedPercent.toFixed(0)}%`}
              </span>
              <span className="fraudgraph-bar-count">
                {showPercents ? `${flaggedPercent.toFixed(0)}%` : Humanize.compactInteger(flagged, 1)}
              </span>
            </Tooltip>
          </div>
        )}
        <div
          className="fraudgraph-bar fraudgraph-clean"
          style={{ '--percent': `${cleanPercent}%` }}
        >
          <Tooltip
            tooltip={Humanize.intComma(clean)}
            tooltipProps={tooltipSpacing}
          >
            <span className="fraudgraph-bar-percent">
              {`${cleanPercent.toFixed(0)}%`}
            </span>
            <span className="fraudgraph-bar-count">
              {showPercents ? `${cleanPercent.toFixed(0)}%` : Humanize.compactInteger(clean, 1)}
            </span>
          </Tooltip>
        </div>
      </div>
      <div className="fraudgraph-info">
        <div className="fraudgraph-title">{title}</div>
        <div className="fraudgraph-total">
          {noData && (noDataMessage || 'No Data')}
          {noData && loading && '0000'}
          {!noData && !loading && (
            <Tooltip tooltip={infoContent || Humanize.intComma(total)}>
              {Humanize.compactInteger(total, 1)}
            </Tooltip>
          )}
        </div>
        {showUnique && !noData && (
          <div className="fraudgraph-sub">
            {
              loading ? 'Unique: 0' : (
                <Tooltip tooltip={Humanize.intComma(unique)}>
                  {`Unique: ${Humanize.compactInteger(unique, 1)}`}
                </Tooltip>
              )
            }
          </div>
        )}
      </div>
    </div>
  );
};

FraudGraph.propTypes = {
  data: PropTypes.shape(),
  title: PropTypes.string.isRequired,
  noDataMessage: PropTypes.string,
  showFlagged: PropTypes.bool,
  showRejected: PropTypes.bool,
  loading: PropTypes.bool,
  showPercents: PropTypes.bool,
  showUnique: PropTypes.bool,
};

FraudGraph.defaultProps = {
  noDataMessage: null,
  showFlagged: true,
  showRejected: true,
  showPercents: false,
  showUnique: false,
  loading: false,
  data: {
    total: 0,
    clean: 0,
    flagged: 0,
    rejected: 0,
    unique: 0,
    infoContent: null,
  },
};

export default FraudGraph;

// FIXME: Tooltip
// FIXME: Loading
