import { useEffect } from 'react';

export default function useOutClick(refs, onOutClick, check, deps) {
  const handleOutsideClick = (event) => {
    if (refs.some((ref) => ref?.current.contains(event.target))) return;

    onOutClick();
  };

  useEffect(() => {
    if (check) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
