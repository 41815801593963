import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

export const logMessage = (res) => {
  const message = {
    message: '',
    logId: '',
  };

  if (res) {
    if (res.data) {
      if (res.data.log) {
        message.message = res.data.message || 'We failed you. Sorry :(';
        message.logId = res.data.log.id || '';
      } else if (res.data.code === 400) {
        if (typeof res.data.errors === 'object' && !isEmpty(res.data.errors) && res.data.errors.children) {
          const errorChildren = Object.keys(res.data.errors.children)
            .filter((child) => !isEmpty(res.data.errors.children[child]));
          const errors = errorChildren.map((child) => res.data.errors.children[child].errors);
          message.message = errors.join('\r\n');
        }
      } else if (res.data.message) {
        message.message = res.data.message || 'We failed you. Sorry :(';
      }
    } else {
      message.message = 'We failed you. Sorry :(';
    }
  }

  return `${message.message}${message.logId !== '' ? ` - ${message.logId}` : ''}`;
};

export const errorMessage = (res) => {
  const message = get(res, 'data.error.message');
  if (typeof message === 'string') {
    return message;
  }

  if (typeof message === 'object') {
    return Object.keys(message).map((key) => `${key.replace('_', ' ')}: ${message[key].join(', ')}`);
  }

  return null;
};
