import React from 'react';

import './Macbook.css';

export default function Macbook() {
  return (
    <div className="macbook">
      <div className="macbook-display">
        <div className="macbook-screen">
          <div className="macbook-interceptd">
            <div className="macbook-logo">
              <div className="macbook-logo-inner">
                <div className="macbook-horizontal" />
                <div className="macbook-vertical" />
                <div className="macbook-vertical-after" />
              </div>
            </div>
            <div className="macbook-text" />
          </div>
        </div>
      </div>
      <div className="macbook-base">
        <div className="macbook-indent" />
      </div>
      <div className="macbook-bottom" />
    </div>
  );
}
