import React from 'react';
import PropTypes from 'prop-types';

import './AppPlaceholder.css';

const AppPlaceholder = ({ loading }) => (
  <div className={`app-ph ${loading ? 'loading' : ''}`}>
    <div className="app-ph-icon" />
    <div className="app-ph-info">
      <div className="app-ph-title" />
      <div className="app-ph-category" />
    </div>
    <div className="app-ph-store" />
  </div>
);

AppPlaceholder.propTypes = {
  loading: PropTypes.bool,
};

AppPlaceholder.defaultProps = {
  loading: false,
};


export default AppPlaceholder;
