import React from 'react';
import PropTypes from 'prop-types';

import './Radio.css';

const Radio = ({
  name,
  label,
  disabled,
  wrapperClassName,
  wrapperProps,
  value,
  checked,
  inputProps,
  visualProps,

  onChange,

  renderBefore,
  renderAfter,
  renderLabel,
}) => (
  <label
    className={`radio-input ${disabled ? 'disabled' : ''} ${wrapperClassName || ''}`}
    {...wrapperProps}
  >
    {renderBefore && renderBefore({ value, checked })}
    <input
      name={name}
      type="radio"
      disabled={disabled}
      value={value}
      defaultChecked={checked}
      onChange={(event) => {
        event.persist();
        if (onChange) onChange(event);
      }}
      {...inputProps}
    />
    <div className="radio-input-visual" {...visualProps} />
    {renderLabel ? renderLabel({ value, checked }) : label && <div className="radio-input-label">{label}</div>}
    {renderAfter && renderAfter({ value, checked })}
  </label>
);

Radio.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  wrapperClassName: PropTypes.string,
  value: PropTypes.string,

  disabled: PropTypes.bool,
  checked: PropTypes.bool,

  wrapperProps: PropTypes.shape(),
  inputProps: PropTypes.shape(),
  visualProps: PropTypes.shape(),

  onChange: PropTypes.func,
  renderBefore: PropTypes.func,
  renderLabel: PropTypes.func,
  renderAfter: PropTypes.func,
};


Radio.defaultProps = {
  name: '',
  value: '',
  wrapperClassName: '',
  label: null,

  disabled: false,
  checked: false,

  wrapperProps: {},
  inputProps: {},
  visualProps: {},

  onChange: null,
  renderBefore: null,
  renderLabel: null,
  renderAfter: null,
};

export default Radio;
