import React from 'react';
import PropTypes from 'prop-types';

import './Box.css';

export const Box = ({
  className,
  wrapperProps,
  headerProps,
  title,
  titleProps,
  left,
  leftProps,
  right,
  rightProps,
  children,
  bodyProps,
}) => (
  <div className={`box ${className}`} {...wrapperProps}>
    <div className="box-header" {...headerProps}>
      <div className="box-header-col box-header-left" {...leftProps}>
        <div className="box-title" {...titleProps}>{title}</div>
        {left}
      </div>
      {right && (
        <div className="box-header-col box-header-right" {...rightProps}>
          {right}
        </div>
      )}
    </div>
    <div className="box-body" {...bodyProps}>
      {children}
    </div>
  </div>
);

Box.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  left: PropTypes.node,
  right: PropTypes.node,
  children: PropTypes.node.isRequired,
  wrapperProps: PropTypes.shape(),
  headerProps: PropTypes.shape(),
  titleProps: PropTypes.shape(),
  leftProps: PropTypes.shape(),
  rightProps: PropTypes.shape(),
  bodyProps: PropTypes.shape(),
};

Box.defaultProps = {
  className: '',
  title: null,
  left: null,
  right: null,
  wrapperProps: {},
  headerProps: {},
  titleProps: {},
  leftProps: {},
  rightProps: {},
  bodyProps: {},
};

export default Box;
