import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class InlineSVG extends Component {
  DataPropPrefix = 'data-';

  convertReactSVGDOMProperty = (str) => str.replace(/[-|:]([a-z])/g, (g) => g[1].toUpperCase())

  startsWith = (str, substring) => str.indexOf(substring) === 0

  serializeAttrs = (map) => {
    const ret = {};
    for (let prop, i = 0; i < map.length; i += 1) {
      const key = map[i].name;
      if (key === 'class') {
        prop = 'className';
      } else if (!this.startsWith(key, this.DataPropPrefix)) {
        prop = this.convertReactSVGDOMProperty(key);
      } else {
        prop = key;
      }

      ret[prop] = map[i].value;
    }
    return ret;
  }

  getSVGFromSource = (src) => {
    const svgContainer = document.createElement('div');
    svgContainer.innerHTML = src;
    const svg = svgContainer.firstElementChild;
    if (svg.remove) {
      svg.remove();
    } else {
      svgContainer.removeChild(svg);
    }
    return svg;
  }

  extractSVGProps = (src) => {
    const map = this.getSVGFromSource(src).attributes;
    return (map.length > 0) ? this.serializeAttrs(map) : null;
  }

  render() {
    const {
      element,
      raw,
      src,
      svgProps,
      ...otherProps
    } = this.props;

    const html = this.getSVGFromSource(src).innerHTML || src;
    const Element = 'svg' || element;
    const elemProps = this.extractSVGProps(src) || svgProps || {};

    return (
      <Element
        {...elemProps}
        {...otherProps}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    );
  }
}

InlineSVG.propTypes = {
  element: PropTypes.string,
  raw: PropTypes.bool,
  src: PropTypes.string,
  svgProps: PropTypes.shape(),
};

InlineSVG.defaultProps = {
  svgProps: {},
  element: 'i',
  raw: false,
  src: '',
};
