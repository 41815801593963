import React, { useState } from 'react';
import TagsInput from 'react-tagsinput';
import TetherComponent from 'react-tether';
import PropTypes from 'prop-types';

import Icon from '../Icon';

import './TagInput.css';

const TagInput = (props) => {
  const [focused, setFocused] = useState(false);
  const selectRef = React.createRef();

  const {
    onFocus,
    onBlur,
    className,
    wrapperProps,
    error,
    warning,
    info,
    disabled,
    required,
    label,
    id,
    name,
    placeholder,
    inputRef,
    spellCheck,
    autoComplete,
    type,
    inputProps,
    withOptions,
    renderOptions,
    tetherProps,
    getTetherProps,
    focused: propsFocused,
    ...rest
  } = props;

  const handleFocus = (event) => {
    setFocused(true);
    onFocus(event);
  };

  const handleBlur = (event) => {
    setFocused(false);
    onBlur(event);
  };

  const pasteSplit = (data) => {
    const separators = [',', ';', '\\(', '\\)', '\\*', '/', ':', '\\?', '\n', '\r'];
    return data.split(new RegExp(separators.join('|'))).map((d) => d.trim());
  };

  const renderLayout = (tagComponents, inputComponent) => (
    <span>
      <span className="tags-tags-wrapper">{tagComponents}</span>
      {inputComponent}
    </span>
  );

  return (
    <TetherComponent
      {...getTetherProps(tetherProps)}
      renderTarget={(ref) => (
        <div
          ref={ref}
          className={`tags-wrapper ${className} ${error ? 'error' : ''} ${warning ? 'warning' : ''} ${disabled ? 'disabled' : ''} ${propsFocused || focused ? 'focused' : ''} ${required ? 'required' : ''} ${rest.value && rest.value !== '' ? 'filled' : ''}`}
          {...wrapperProps}
        >
          <div ref={selectRef}>
            {label && <label className="tags-label" htmlFor={id || name}>{label}</label>}
            <TagsInput
              className="tags-input-wrapper"
              inputProps={{
                name,
                type,
                spellCheck,
                placeholder,
                autoComplete,
                ref: inputRef,
                className: 'tags-input',
                onFocus: handleFocus,
                onBlur: handleBlur,
                id: id || name,
                ...inputProps,
              }}
              tagProps={{
                className: 'tags-tag',
                classNameRemove: 'tags-tag-remove',
              }}
              disabled={disabled}
              renderTag={({
                tag,
                key,
                disabled: disabledTag,
                onRemove,
                classNameRemove,
                getTagDisplayValue,
                ...other
              }) => (
                <span key={key} {...other}>
                  <span>{getTagDisplayValue(tag)}</span>
                  {!disabledTag && <Icon i="x" size={14} onClick={() => onRemove(key)} />}
                </span>
              )}
              renderLayout={renderLayout}
              addKeys={[9, 13, 188]}
              addOnPaste
              pasteSplit={pasteSplit}
              {...rest}
            />
            <span className="tags-info">{error || warning || info}</span>
          </div>
        </div>
      )}
      renderElement={(ref) => (
        withOptions && (
          <div
            ref={ref}
            style={{
              minWidth: (selectRef && selectRef.current)
                ? selectRef.current.getBoundingClientRect().width
                : 200,
            }}
          >
            {renderOptions && renderOptions(propsFocused || focused)}
          </div>
        )
      )}
    />
  );
};

TagInput.propTypes = {
  inputRef: PropTypes.node.isRequired,

  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  getTetherProps: PropTypes.func,
  className: PropTypes.string,
  wrapperProps: PropTypes.shape(),
  tetherProps: PropTypes.shape(),
  inputProps: PropTypes.shape(),
  withOptions: PropTypes.shape(),
  renderOptions: PropTypes.func,

  error: PropTypes.string,
  warning: PropTypes.string,
  info: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  autoComplete: PropTypes.string,

  spellCheck: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  focused: PropTypes.bool,
};


TagInput.defaultProps = {
  onFocus: () => null,
  onBlur: () => null,

  error: null,
  warning: null,
  info: null,
  label: null,
  id: null,

  className: '',
  type: 'text',
  spellCheck: false,
  disabled: false,
  required: false,
  focused: false,
  autoComplete: 'nope',

  inputProps: {},
  withOptions: {},
  renderOptions: null,
  wrapperProps: {},
  tetherProps: {
    attachment: 'top left',
    targetAttachment: 'bottom left',
    constraints: [
      {
        to: 'window',
        attachment: 'together',
        pin: true,
      },
    ],
  },
  getTetherProps: (props) => props,
};

export default TagInput;
