import React, { useState, createRef } from 'react';
import PropTypes from 'prop-types';
import TetherComponent from 'react-tether';

import Icon from '../Icon';

import Item from './Item';

import useOutClick from '../../hooks/use-out-click';

import './Actions.css';

export default function Actions({
  className,
  triggerContent,
  children,
  attachment,
  targetAttachment,
  tetherProps,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const targetRef = createRef();
  const elementRef = createRef();

  useOutClick([targetRef, elementRef], () => {
    setIsOpen(false);
  }, isOpen, [isOpen]);

  return (
    <div className={`actions ${className}`}>
      <TetherComponent
        {...tetherProps}
        attachment={attachment}
        targetAttachment={targetAttachment}
        renderTarget={(ref) => (
          <div ref={ref}>
            <div ref={targetRef} className={`actions-trigger ${isOpen ? 'active' : ''}`} onClick={() => setIsOpen(!isOpen)}>
              {triggerContent || <Icon i="more-vertical" size={17} />}
            </div>
          </div>
        )}
        renderElement={(ref) => isOpen && (
          <div ref={ref}>
            <div ref={elementRef} className="actions-list" onClick={() => setIsOpen(false)}>
              {children}
            </div>
          </div>
        )}
      />
    </div>
  );
}

Actions.propTypes = {
  className: PropTypes.string,
  triggerContent: PropTypes.node,
  children: PropTypes.node.isRequired,
  attachment: PropTypes.string,
  targetAttachment: PropTypes.string,
  tetherProps: PropTypes.shape(),
};

Actions.defaultProps = {
  className: '',
  triggerContent: null,
  attachment: 'top right',
  targetAttachment: 'bottom right',
  tetherProps: {},
};

Actions.Item = Item;
