
import { getUnixTime } from 'date-fns';

export const TransformOptions = (data, placeholder, iterator = null) => {
  const transformed = [
    {
      text: placeholder,
      value: 0,
      placeholder: true,
    },
  ];

  Object.keys(data).map((value) => {
    let newObject;

    if (iterator) {
      newObject = iterator(data, value);
    } else {
      newObject = {
        text: data[value],
        value,
      };
    }

    return transformed.push(newObject);
  });
  return transformed;
};

export const DashedtoAbbr = (str) => str.split('-').map((i) => i[0].toUpperCase()).join('');

export const ToFloat = (number) => {
  if (
    !Number.isNaN(number)
    && Number.isFinite(number)
    && number % 1 !== 0
  ) {
    return number.toFixed(2);
  }
  return number;
};

export const ToParam = (payload, encode = true) => Object.keys(payload).reduce((result, k) => {
  if (payload[k] !== null && payload[k] !== undefined && payload[k] !== '') {
    result.push(`${(encode ? encodeURIComponent(k) : k)}=${(encode ? encodeURIComponent(payload[k]) : payload[k])}`);
  }
  return result;
}, []).join('&');

export function getParameterByName(pathName, route) {
  const url = route || window.location.href;
  const name = pathName.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2]);
}

export const fillHours = (data) => {
  const hours = [];

  for (let i = 0; i < 24; i += 1) {
    hours.push({ x: i, y: 0 });
  }

  return hours.map((h) => {
    const d = data.filter((v) => v.x === h.x)[0];
    return ({
      ...h,
      y: d ? d.y : h.y,
    });
  });
};

export function getTimeStamp(date, isEnd = false) {
  if (date instanceof Date === false) {
    throw new Error('Missing required param: date');
  }

  const year = date.getUTCFullYear();
  const month = date.getUTCMonth();
  const day = date.getUTCDate();
  const hour = date.getUTCHours();
  const minute = date.getUTCMinutes();
  const second = date.getUTCSeconds();
  const newDate = new Date(year, month, day, hour, minute, second);
  newDate.setUTCHours(isEnd ? 23 : 0);
  newDate.setUTCMinutes(isEnd ? 59 : 0);
  newDate.setUTCSeconds(isEnd ? 59 : 0);

  return getUnixTime(newDate);
}
